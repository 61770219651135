<template>
  <div class="passageContainer" v-if="passage">
    <h2 style="padding-left:24px;text-align:center;margin-bottom:20px">
      {{ passage.title }}
    </h2>
    <div>
      <div style="margin-bottom: 20px" v-if="passage.intro">
        <div class="passage-intro" v-html="passage.intro" />
      </div>
    </div>
    <div>
      <div v-if="passage.below_content">
        <h5>Passage I</h5>
      </div>
      <div
        class="passage-content"
        v-html="getPassage(passage.content, passage.below_content)"
      />
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  components: {},
  mixins: [],
  props: ["passage", "section"],
  data() {
    return {};
  },
  mounted() {
    this.addIntroMargin();
    this.addLineNumber();
    this.narrowImage();
    this.setQuestionNumberStyle();
    this.setQuestionNumberStyleWithBaseline();
  },

  methods: {
    getPassage(p1,p2){
      let passage = p1;
      if(p2){
        passage+= `<h5>Passage II</h5>${p2}`;
      }
      return passage;
    },
    narrowImage() {
      let passageContent = $(".passage-content");
      $.each(passageContent, function(key, val) {
        let line = 1;
        for (let i = 0; i < $(val).find("img").length; i++) {
          $(val).find("img")[i].style.cssText = "width: 100%";
        }
      });
    },
    addIntroMargin() {
      if (this.section === "reading") {
        let passageIntro = $(".passage-intro");
        $.each(passageIntro, function(key, val) {
          for (let i = 0; i <= $(val).find("p").length; i++) {
            if (
              $.trim($($(val).find("p")[i]).html()) != "" &&
              $.trim($($(val).find("pre")[i]).html()) != "&nbsp;" &&
              !$.trim($($(val).find("p")[i]).html()).includes("<img")
            ) {
              $($(val).find("p")[i]).prepend(
                "<b class='text-success' style='display: inline-block;width:30px'></b>"
              );
            }
          }
        });
      }
    },
    addLineNumber() {
      if (this.section === "reading") {
        let passageContent = $(".passage-content");
        $.each(passageContent, function(key, val) {
          let line = 1;

          for (let i = 0; i <= $(val).find("p").length; i++) {
            if (
              $.trim($($(val).find("p")[i]).html()) != "" &&
              $.trim($($(val).find("pre")[i]).html()) != "&nbsp;" &&
              !$.trim($($(val).find("p")[i]).html()).includes("<img")
            ) {
              let num = line / 5;
              if ((line == 1 || parseInt(num) == num) && line > 1) {
                $($(val).find("p")[i]).prepend(
                  `<b class="text-success" style="text-align: right; display: inline-block;width:30px; padding-right: 4px">${line}</b>`
                );
              } else if (i === 3) {
                $($(val).find("p")[i]).prepend(
                  "<span class=\"text-success\" style=\"text-align: right; display: inline-block;width:30px; font-style: italic; padding-right: 4px\">line</span>"
                );
              } else {
                $($(val).find("p")[i]).prepend(
                  "<b class='text-success' style='display: inline-block;width:30px'></b>"
                );
              }
              line = line + 1;
            }
          }
        });
      }
    },
    setQuestionNumberStyle() {
      const getQuestionNumberStyle = () => {
        return "background:#000000;border-radius:4px;color:#ffffff;margin:0px 4px;padding:0px 4px;";
      };

      const passageContent = $(".passage-content");
      $.each(passageContent, function(key, val) {
        for (let i = 0; i < $(val).find("span").length; i++) {
          if ($(val).find("span")[i].className && $(val).find("span")[i].className.includes("Question")) {
            $(val).find("span")[i].style = getQuestionNumberStyle();
          }
        }
      });
    },
    setQuestionNumberStyleWithBaseline() {
      const getCurrentPassageTargetBaselineIndex = (passageContent) => {
        let targetIndex = -1;
        if (passageContent.includes("</u><span")) {
          targetIndex = passageContent.indexOf("</u><span");
        } else {
          targetIndex = passageContent.indexOf("</span><u");
        }
        return targetIndex;
      };

      let targetIndex = null;

      const passageContents = $(".passage-content");
      $.each(passageContents, function(index, passageContentElement) {
        let passageContent = passageContentElement.innerHTML;
        while (passageContent.includes("</u><span")) {
          // take span tag
          targetIndex = getCurrentPassageTargetBaselineIndex(passageContent);
          let spanTag = "";
          const startSpanTagIndex = targetIndex + 4;
          let endSpanTagIndex = startSpanTagIndex;
          while (!spanTag.includes("</span>")) {
            spanTag = `${spanTag}${passageContent[endSpanTagIndex]}`;
            endSpanTagIndex += 1;
          }
          passageContent = passageContent.replace(spanTag, "");

          // find start index of u tag
          let startUTagIndex = targetIndex;
          let uTag = "";
          while (!uTag.includes("<u>")) {
            uTag = `${passageContent[startUTagIndex]}${uTag}`;
            startUTagIndex -= 1;
          }
          // insert span tag on the front u tag
          passageContent = `${passageContent.slice(0, startUTagIndex + 1)}${spanTag}${passageContent.slice(startUTagIndex + 1)}`;
        }

        $(".passage-content")[index].innerHTML = passageContent;
      });
    }
  }
};
</script>

<!-- <style scoped src="@/views/satTest/style/tpo.css"></style> -->
<style scoped>
.passageContainer {
  width: max-content;
  margin: 0px auto;
}
.passage-content, .passage-intro {
  line-height: 34px;
}
.passage-content >>> p, .passage-intro >>> p {
  margin-bottom: 0px;
  white-space: nowrap;
}
</style>
